// Countdown timer based on
// https://www.w3schools.com/howto/howto_js_countdown.asp

export default function countDownTimer(date, el) {
  // Set the date we're counting down to
  var countDownDate = new Date(parseInt(date)).getTime();

  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    el.html(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

    // If the count down is finished
    if (distance < 0) {
      clearInterval(x);
      el.html("");
      $(el).trigger("countdown:expired");
    }
  }, 1000);

  const cleanup = () => {
    clearInterval(x);
    document.removeEventListener("turbolinks:visit", cleanup);
  }
  document.addEventListener("turbolinks:visit", cleanup);
}
