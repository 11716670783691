export default () => {
  let toRevoke = [];
  let toCleanup = [];

  const videos = document.querySelectorAll('.video-attachment.video-autoplay');

  videos.forEach(video => {
    let getPaused = () => Promise.resolve(false);
    let getPlayer = () => Promise.resolve(null);

    if (video.nodeName === "VIDEO") {
      getPlayer = () => Promise.resolve(video);
      getPaused = (video) => Promise.resolve(video.paused);
    }

    if (video.nodeName === "IFRAME" && video.src.includes("vimeo")) {
      getPlayer = () => Promise.resolve(new Vimeo.Player(video));
      getPaused = (video) => video.getPaused();
    }

    if (video.nodeName === "IFRAME" && video.src.includes("videodelivery")) {
      getPlayer = () => Promise.resolve(Stream(video));
      getPaused = (stream) => Promise.resolve(stream.paused);
    }

    if (video.classList.contains("wistia_embed")) {
      getPlayer = () => new Promise((resolve) => {
        window._wq = window._wq || [];
        const config = {
          id: video.id,
          onReady: (player) => resolve(player)
        };
        window._wq.push(config);
        toRevoke.push(config);
      });
      getPaused = (player) => Promise.resolve(player.state() !== "playing");
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        const player = await getPlayer()

        if (!player) {
          console.error("Tried to autoplay an unsupported video.");
          return;
        }

        const paused = await getPaused(player)

        if (entry.intersectionRatio < 0.5 && !paused) {
          player.pause();
        }
        else if (entry.intersectionRatio >= 0.5 && paused) {
          player.play();
        }
      });
    }, { threshold: 0.5 });
    observer.observe(video);

    toCleanup.push(observer);
  });

  const cleanup = () => {
    toRevoke.forEach(config => {
      window._wq = window._wq || [];
      _wq.push({ revoke: config });
    });
    toRevoke = [];

    toCleanup.forEach(observer => {
      observer.disconnect();
    });
    toCleanup = [];

    document.removeEventListener("turbolinks:visit", cleanup);
  }
  document.addEventListener("turbolinks:visit", cleanup);
};
