export default () => {
  $(".pop")
    .popover({
      trigger: "manual",
      html: true,
      animation: false,
      container: "body",
    })
    .on("mouseenter", function () {
      var _this = this;
      $(_this).popover("show");
      $(".popover").on("mouseleave", function () {
        $(_this).popover("hide");
      });
    })
    .on("mouseleave", function () {
      if (!$(".popover:hover").length) {
        $(this).popover("hide");
      }
    });

  $(".popover").remove();
};

$(document).on('touchstart', function () {
  $('[data-toggle="tooltip"]').tooltip('hide')
})
