import Chart from "chart.js/auto"

const { layoutHelpers } = window

layoutHelpers.openNav = () => {
  document.getElementById("nav-menu-sm").style.width = "100%";
}

layoutHelpers.closeNav = () => {
  document.getElementById("nav-menu-sm").style.width = "0%";
}

const collapseBsTabs = (menu, maxHeight) => {
  var nav = $(menu)
  var navHeight = nav.innerHeight()
  if (navHeight >= maxHeight) {
    $(`${menu}.navbar-nav`).removeClass('w-auto').addClass("w-100");

    if ($(`${menu} .dropdown-menu #new_tab_link`).length > 0) {
      $('<div class="dropdown-divider"></div>').insertBefore(`${menu} .dropdown-menu #new_tab_link`);
    }

    let menuItem;
    while (navHeight > maxHeight) {
      //  add child to dropdown
      $(`${menu} .dropdown`).show();
      var children = nav.children(`${menu} li:not(:last-child)`);
      menuItem = $(children[children.length - 1]);
      menuItem.prependTo(`${menu} .dropdown-menu`);
      menuItem.removeClass('nav-item');
      menuItem.children('a').removeClass('nav-link').addClass('dropdown-item');
      navHeight = nav.innerHeight();
    }

    $(`${menu}.navbar-nav`).addClass("w-auto").removeClass('w-100');

  } else {
    var collapsed = $(`${menu} .dropdown-menu`).children(`${menu} li`);
    $(`${menu} .dropdown-menu .dropdown-divider`).remove();

    let menuItem;
    while (navHeight < maxHeight && (nav.children(`${menu} li`).length > 0) && collapsed.length > 0) {
      //  remove child from dropdown
      collapsed = $(`${menu} .dropdown-menu`).children('li');
      menuItem = $(collapsed[0]);
      menuItem.insertBefore(nav.children(`${menu} li:last-child`));
      menuItem.addClass('nav-item');
      menuItem.children('a').removeClass('dropdown-item').addClass('nav-link');
      navHeight = nav.innerHeight();
    }

    if ($(`${menu} .dropdown-menu`).children().length === 0) {
      $(`${menu} .dropdown`).hide();
    }

    if (navHeight > maxHeight) {
      collapseBsTabs(menu, maxHeight);
    }
  }
};

// when the page loads
$(document).on('ready', () => {
  collapseBsTabs('#community_nav', 50);
});

// when the window is resized
$(window).on('resize', () => {
  setTimeout(() => collapseBsTabs('#community_nav', 50), 1);
});

// when navigating
document.addEventListener('turbolinks:load', () => {
  setTimeout(() => collapseBsTabs('#community_nav', 50), 1);
});

export const initDonutChart = (el, progress, color, onResize) => {
  progress = parseInt(progress)
  if (!el) {
    return null;
  }

  return new Chart(el.getContext("2d"), {
    type: 'doughnut',
    data: {
      datasets: [{
        data: [progress, 100 - progress],
        backgroundColor: [color, '#E6EEF6'],
        hoverBackgroundColor: [color, '#E6EEF6'],
        borderWidth: 0
      }]
    },
    options: {
      scales: {
        xAxes: [{
          display: false,
        }],
        yAxes: [{
          display: false
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      cutoutPercentage: 86,
      responsive: true,
      maintainAspectRatio: true,
      onResize: function () { eval(onResize) }
    }
  });
}