export function wistiaChapterSetup(player, video_id) {
  const chapters = player.data.media.embed_options.plugin.chapters;
  const url_code = player.data.media.hashedId;
  if (chapters && chapters.chapterList.length > 0 && chapters.chapterList.some(c => c.deleted === 'false')) {
    $('.video-chapter-container').addClass('d-sm-block');
    $('.video-container').addClass('col-md-9').removeClass('col-md-12');
    $('.disclaimer-container').removeClass('col-md-8');
    chapters.chapterList.sort((a,b) => a.time - b.time).forEach(chapter => {
      if (chapter.deleted === "false") {
        const formatted_time = videoFormatSeconds(chapter.time)
        $(`.video-chapters-${video_id}`)
          .append(`<div><a href="#wistia_` + url_code + `?time=` + chapter.time +
            `" class="list-group-item rounded-0 video-chapters">` +
            `<span class="small badge badge-secondary badge-pill badge-pt-fix mr-2">` + formatted_time + `</span>` +
            chapter.title +
            `</a></div>`)
      }
    });
  } else {
    $('.video-chapter-container').removeClass('d-sm-block');
    $('.video-container').removeClass('col-md-9').addClass('col-md-12');
  }
  const div16By27 = document.querySelector('.embed-responsive-16by27')
  const resizeObserver16by27 = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.borderBoxSize) {
        const borderBoxSize = entry.borderBoxSize[0];
        // Using vanilla JS for slightly better performance when resizing
        div16By27.style.maxHeight = borderBoxSize.inlineSize * 1.6875 + "px"
      }
    }
  })
  if (div16By27) {
    resizeObserver16by27.observe(div16By27)
    const cleanup = () => {
      resizeObserver16by27.unobserve(div16By27)

      $('.video-chapter-container').removeClass('d-sm-block').hide()
      $('.disclaimer-container').addClass('col-md-8')
      $(`.video-chapters-${video_id} a.video-chapters`).remove()

      document.removeEventListener("turbolinks:visit", cleanup);
    }
    document.addEventListener("turbolinks:visit", cleanup);
  }
}

function videoFormatSeconds(seconds) {
  let formatted_time = Math.floor(seconds/60 % 60) + ":" + (Math.floor(seconds) % 60).toString().padStart(2, "0")
  if (seconds >= 3600) {
    const hours = Math.floor(seconds/3600);
    formatted_time = hours + ":" + formatted_time.padStart(5, "0")
  }
  return formatted_time
}
