// Global js include for CAMP

let Camp = {}

Camp.toggle = function(elements) {
  elements.forEach(function (e) {
    $(e).toggleClass('d-none')
  })
}
Camp.notify = function(message) {
  $.growl.notice({ title: '', message: message, duration: 10000 })
}

Camp.validate_abiomed_email = function(email) {
  return email.match(/@((abiomed)|(jnj)|(its\.jnj))\.com$/i) != null;
}

document.Camp = Camp