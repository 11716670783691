/**
 * Javascript for Quiz UI
 */

let counter = 5;
const quizHandler = function () {
  let tpl = $('#question_choice_tpl').html().replace(/_counter_/g, counter++)
  $('#question_choices').append(tpl)
};

document.addEventListener('turbolinks:load', function () {
  counter = 5;
  $('[data-add-question-choice]').on('click', quizHandler)
})

document.addEventListener('turbolinks:visit', function () {
  $('[data-add-question-choice]').off('click', quizHandler)
})

let Quiz = {}
Quiz.deleteQuestionChoice = function (event) {
  if (confirm('Do you want to delete the Answer Choice?')) {
    $(event.target).closest('div.input-group').remove()
  }
}

document.Quiz = Quiz
