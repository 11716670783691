export function onTurbolinksLoad(f, cleanupCallback) {
  const cleanupOnLoad = () => {
    // Ensure you destroy anything you instantiated here (dropzone, tribute, etc)
    if (cleanupCallback) {
      cleanupCallback();
    }
    $(document).off("turbolinks:load", onLoad);
    $(document).off("turbolinks:visit", cleanupOnLoad);
  }

  const onLoad = () => {
    f();
    // The line below will clean things up right before we navigate away
    $(document).on("turbolinks:visit", cleanupOnLoad);
  }

  $(document).on("turbolinks:load", onLoad);
}
