require("dropzone");
import initPage from "./init_page";

// type must be one of "comment", "compact_comment", "forum_topic", or "compact_forum_topic"
// Required options: alias
// For regular forum posts, options must include redirect url
export function initializeDropzone(uniqID, type, options) {
    const attached = $(`#${uniqID}`).dropzone({
      autoProcessQueue: false,
      uploadMultiple: true,
      acceptedFiles: null,
      parallelUploads: 100,
      maxFiles: 50,
      clickable: ['forum_topic_', 'compact_forum_'].includes(uniqID) ? `.dz-message` : true,
      timeout: 3600000,
      maxFilesize: 1000,
      previewsContainer: `#${uniqID}preview`,
      paramName: "files",
      headers: { Accept: "text/javascript" },
      previewTemplate:
        "" +
        "<div class='dz-preview dz-file-preview'>" +
        " <div class='dz-details mb-1' style='display: flex; justify-content: center; align-content: start;'>" +
        "   <div style='width: 120px; height: 120px; position: relative; background-color: black; border-radius: 5px' class='mr-1 shadow-sm'>" +
        "     <div class='dz-filename' style='z-index: 0; position: absolute; white-space: wrap; overflow: hidden; text-overflow: clip; width: 120px; height: 120px; color: #8897aa' data-dz-name></div>" +
        "     <img style='position: relative; border-radius: 5px; width: 120px; height: 120px' data-dz-thumbnail>" +
        "       <i class='fas fa-trash position-absolute p-2 cursor-pointer delete-button' data-dz-remove></i>" +
        "     </img>" +
        " </div>" +
        "</div>" +
        "<div class='dz-progress'><span class='dz-upload' data-dz-uploadprogress></span></div>" +
        "</div>",

      init: function () {
        let submitButton = document.querySelector(`#${uniqID}submit`);
        let thisDropzone = this;

        submitButton.addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();

          const forumInput = document.querySelector(`#${uniqID} #selected_forum_id`);
          const textInput = document.getElementById(`${uniqID}text`);
          if (forumInput && !forumInput.value) {
            alert(`Couldn't post ${options.alias}; forum can't be blank.`);
            return;
          }
          else if (textInput && textInput.value.replace(/&nbsp;/g, '').trim().length == 0) {
            alert(`Couldn't post ${options.alias}; text field can't be blank.`);
            return;
          }
          // Uploads an empty blob if there's no image
          // Otherwise dropzone cannot process the queue and send data
          else {
            $(`#${uniqID}progress`).removeAttr("hidden"); //fix id for progress bar
            $(`#${uniqID}submit`).attr("hidden", "true"); //fix id for submit button
            $(`#${uniqID}submit_disabled`).removeAttr("hidden"); //fix id for submit_disabled button
            if (thisDropzone.getQueuedFiles().length === 0) {
              let blob = new Blob();
              blob.upload = { chunked: false };
              thisDropzone.uploadFile(blob);
            } else {
              thisDropzone.processQueue();
            }
          }
        });

        // Handles all errors with selecting or uploading files
        thisDropzone.on("errormultiple", function (file, response) {
          alert(response);
          $(`#${uniqID}progress`).attr("hidden", "true");
          $(`#${uniqID}submit_disabled`).attr("hidden", "true");
          $(`#${uniqID}submit`).removeAttr("hidden");
          thisDropzone.removeFile(file[0]);
        });

        // Updates the progress bar
        thisDropzone.on("totaluploadprogress", function (progress) {
          let prog = Math.round(progress * 10) / 10;
          $(`#${uniqID}submit_disabled`).text(`Posting... ${prog}%`);
          $(`#${uniqID}progress`).width(`${progress}%`);
        });

        thisDropzone.on("successmultiple", function (files, response) {
          switch (type) {
            case "quiz_question":
              window.location.href = options.url;
              break;
            case "update_forum_topic":
              eval(response);
              break;
            default:
              document.getElementById(`${uniqID}text`).value = "";
              // for mentions contenteditable components
              if (document.getElementById(`${uniqID}content`))
                document.getElementById(`${uniqID}content`).innerHTML = "";
              eval(response);
          }
          thisDropzone.removeAllFiles(true);
          $(`#${uniqID}progress`).attr("hidden", "true");
          $(`#${uniqID}submit_disabled`).attr("hidden", "true");
          $(`#${uniqID}submit`).removeAttr("hidden");
          initPage();
        });
      },
    });

    const cleanupDropzones = () => {
      attached.each((i, element) => {
        element.dropzone.destroy();
        element.dropzone = null;
        delete element.dropzone;
      });

      document.removeEventListener("turbolinks:visit", cleanupDropzones);
    }

    document.addEventListener("turbolinks:visit", cleanupDropzones);
}
