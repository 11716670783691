import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';

window.addEventListener("click", (event) => {
  // Filter out any click that has no PhotoSwipe data embedded
  const { dataset } = event.target;
  const { photoswipeGallery, photoswipeIndex } = dataset;
  if (!photoswipeGallery) {
    return;
  }

  event.stopPropagation();
  event.preventDefault();

  // Build out the Photoswipe params
  const dataSource = window.photoswipeGalleries[photoswipeGallery];
  const index = parseInt(photoswipeIndex);

  // Initialize and open PhotoSwipe
  const lightbox = new PhotoSwipe({
    pswpModule: () => import('photoswipe'),
    index,
    dataSource,
  });

  lightbox.addFilter('thumbBounds', (thumbBounds, itemData, index) => {
    const thumbnail = document.querySelector(`img[data-photoswipe-gallery="${photoswipeGallery}"][data-photoswipe-index="${index}"]`);
    if (!thumbnail) {
      return thumbBounds;
    }

    const rect = thumbnail.getBoundingClientRect();
    return { x: rect.left, y: rect.top, w: rect.width, h: rect.height };
  });

  lightbox.init();
})
