import Tribute from "tributejs";

async function remoteSearch(text, model_name, model_id, cb) {
  if (text.length > 0) {
    let data = { q: text, exclude_self: true }
    if (model_name) data['model_name'] = model_name
    if (model_id) data['model_id'] = model_id
    const result = await $.ajax("/accounts/search.json", {
      type: "GET",
      data,
      dataType: "json",
    });
    cb(result);
  } else {
    cb([])
  }
}

export function attachTribute(element_id, model_name = null, model_id = null) {
  if (document.documentElement.hasAttribute("data-turbolinks-preview")) {
    // Turbolinks is displaying a preview, so we'll wait
    // until it's rendered the real version before proceeding
    return null;
  }

  const tribute = new Tribute({
    collection: [
      {
        values: function (text, cb) {
          remoteSearch(text, model_name, model_id, users => cb(users));
        },
        lookup: 'display_name',
        fillAttr: 'display_name',
        menuItemTemplate: function (item) {
          return item.original.display_name;
        },
        selectTemplate: function (item) {
          if (typeof item === "undefined") return null;
          if (this.range.isContentEditable(this.current.element)) {
            return (`<span contenteditable="false"><a href="/profile/${item.original.id}" target="_blank">@${item.original.display_name}</a></span>`);
          }
        },
        noMatchTemplate: function () {
          return '<span style:"visibility: hidden;"></span>';
        },
        menuShowMinLength: 1,
        menuItemLimit: 25,
      }
    ],
  });

  const onPaste = (e) => {
    e.preventDefault();
    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
    text = text.replace(/•/g, '*'); // to be more markdown ready
    document.execCommand("insertText", false, text);
  }

  const onKeydown = (event) => {
    if (window.getSelection && event.which == 8) { // backspace
      var selection = window.getSelection();
      if (!selection.isCollapsed || !selection.rangeCount) {
        return;
      }

      var curRange = selection.getRangeAt(selection.rangeCount - 1);
      if (curRange.commonAncestorContainer.nodeType == 3 && curRange.startOffset > 0) {
        return;
      }

      var range = document.createRange();
      if (selection.anchorNode != this) {
        // selection is in character mode. expand it to the whole editable field
        range.selectNodeContents(this);
        range.setEndBefore(selection.anchorNode);
      } else if (selection.anchorOffset > 0) {
        range.setEnd(this, selection.anchorOffset);
      } else {
        // reached the beginning of editable field
        return;
      }
      range.setStart(this, range.endOffset - 1);

      var previousNode = range.cloneContents().lastChild;
      if (previousNode && previousNode.contentEditable == 'false') {
        range.deleteContents();
        // form fields are updated on input event so we should dispatch it manually
        this.dispatchEvent(new Event('input', { bubbles: true }))
        event.preventDefault();
      }
    }
  }

  const destination = document.getElementById(element_id);
  if (!destination) {
    return () => {};
  }

  destination.addEventListener("paste", onPaste);
  // fix backspace bug in FF
  // https://bugzilla.mozilla.org/show_bug.cgi?id=685445
  var isFF = !!navigator.userAgent.match(/firefox/i)
  if (isFF) {
    destination.addEventListener("keydown", onKeydown);
  }
  tribute.attach(destination);

  const detach = () => {
    destination.removeEventListener("paste", onPaste);
    if (isFF) {
      destination.removeEventListener("keydown", onKeydown);
    }
    tribute.detach(destination);
    document.removeEventListener("turbolinks:before-cache", detach);
  }
  document.addEventListener("turbolinks:before-cache", detach);

  return detach;
}
