import Rails from "@rails/ujs";

let observers = [];

const registerObservers = () => {
  const cleanup = () => {
    observers.forEach(observer => observer.disconnect());
    observers = [];
    document.removeEventListener("turbolinks:visit", cleanup);
  }

  cleanup();

  const feeds = document.querySelectorAll(".infinite-scroll");
  feeds.forEach(feed => {
    const trigger = feed.querySelector(".infinite-scroll-trigger");

    const observer = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        const url = entry.target.dataset.url;
        const loading = entry.target.dataset.loading === "true";
        const done = entry.target.dataset.done === "true";

        const page = parseInt(entry.target.dataset.page);
        const time = parseInt(entry.target.dataset.time);

        if (entry.isIntersecting && !loading && !done) {
          entry.target.dataset.loading = "true";

          const endpoint = new URL(url);
          if (!isNaN(page)) {
            endpoint.searchParams.set('page', page);
          }
          if (!isNaN(time)) {
            endpoint.searchParams.set('time', time);
          }

          Rails.ajax({
            url: endpoint.toString(),
            type: "GET",
            success: (res) => {
              entry.target.dataset.loading = "false";

              if (entry.target.dataset.callback) {
                eval(`${entry.target.dataset.callback}`)();
              }

              if (res.includes("infinite-scroll-end")) {
                entry.target.dataset.done = "true";
              } else {
                // Delay resetting the observer to avoid choppy updates
                setTimeout(() => {
                  observer.unobserve(trigger);
                  observer.observe(trigger);
                }, 1000);
              }
            },
            error: (response, statusText, xhr) => {
              entry.target.dataset.loading = "false";

              if (xhr.status == 401) {
                window.location.reload();
              }
            }
          });
        }
      });
    }, { threshold: 0.5 });
    observer.observe(trigger);

    observers.push(observer);
  });

  document.addEventListener("turbolinks:visit", cleanup);
};

$(document).on("turbolinks:load", () => {
  registerObservers();
});
