// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("../channels");

require("../../stylesheets/application.scss")

require.context('../../images', true)

// Include Appwork main file
require("../appwork/main")

import "chartkick/chart.js";
require("dropzone")

import ahoy from "ahoy.js";
import './quiz'
import './infinite_scroll'
import './camp_global'
import './sticky_sidebars'
import { initDonutChart } from './layout_scripts.js'
import './photoswipe'
import { wistiaChapterSetup } from './wistia-helper'
import countDownTimer from './countdown.js'
import './for_mobile'
import dragscroll from 'dragscroll'
import initPage from './init_page'
import * as bootbox from 'bootbox/dist/bootbox.all';

// Support component names relative to this directory:
var componentRequireContext = require.context("javascript/components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Use custom Ahoy path to avoid adblockers
ahoy.configure({
  visitsUrl: "/ingest/visits",
  eventsUrl: "/ingest/events",
});

$(document).on("turbolinks:load", () => {
  if (!window._IMPERSONATING && !window._DISABLE_AHOY) { ahoy.trackView(); }
  $('[data-toggle="tooltip"]').tooltip();

  $('input.timepicker').timepicker({
    timeFormat: 'h:i a',
    interval: 15,
    minTime: '8:00 am',
    maxTime: '10:00 pm',
    defaultTime: '8:00 am',
    startTime: '8:00 am',
    dynamic: false,
    dropdown: true,
    scrollbar: true
  })
  $('input[data-focus=true]').first().focus();
  $('[data-delete-attachment]').each((i, el) => {
    el.onclick = function () {
      bootbox.confirm('Do you want to delete the attachment? This cannot be undone.', function (ok) {
        if (!ok) return;
        let id = $(el).attr('data-delete-attachment')
        $.ajax('/attachments/' + id, { method: 'DELETE' })
          .done(function () {
            $('#attachment-' + id).remove()
            $.growl.notice({ message: 'Attachment has been successfully deleted.', duration: 5000 })
          })
      })
    }
  })

  $(document).on("click", '[data-edit-attachment]', function () {
    let id = $(this).attr('data-edit-attachment')
    let att = $('#attachment-' + id)
    att.parent().append(`<input name='deleted_file_ids[]' type='hidden' value='${id}'/>`)
    att.remove()
  });

  $('[data-select2-el]').each((i, el) => {
    $(el).select2({
      placeholder: $(el).attr('placeholder')
    })
  })

  $('[data-chart-type="donut"]').each((i, el) => {
    const chart = initDonutChart(el, $(el).attr('data-chart-progress'), $(el).attr('data-chart-color'), $(el).attr('data-on-resize'))
    const cleanup = () => {
      chart?.destroy();
      document.removeEventListener("turbolinks:visit", cleanup);
    }
    document.addEventListener("turbolinks:visit", cleanup);
  })

  $('[data-countdown-to]').each((i, el) => {
    el = $(el)
    countDownTimer(el.attr('data-countdown-to'), el)
  })

  $('[data-growl]').each((i, el) => {
    if ($('.onpage-alert,.onpage-notice').length) return;
    if ($(el).attr('data-growl') == 'alert') {
      $.growl.warning({ title: 'Oops!', message: el.innerHTML, duration: 10000 })
    } else {
      $.growl.notice({ title: '', message: el.innerHTML, duration: 10000 })
    }
    $(el).remove()
  });

  $('[data-track-video-progress]').each((i, el) => {
    const $el = $(el);
    const provider = $el.data('video-provider');

    const last_video_location = parseInt($el.data('last-video-location'));

    let progress = 0;
    const progress_update_path = $el.data('track-video-progress');

    if (provider === "vimeo") {
      const player = new Vimeo.Player(el);

      if (!player) {
        console.error("A Vimeo video was embedded, but the Vimeo Player API couldn't instantiate.");
        return;
      }

      if (last_video_location > 0) {
        player.setCurrentTime(last_video_location);
      }

      const timeupdate = function ({ percent, seconds }) {
        const pct = parseInt(percent * 100)
        if (progress != pct) {
          progress = pct;
          $.ajax({ url: progress_update_path, method: 'post', data: { progress, seconds } });
          if (progress >= 99) {
            // This is a workaround for calling $el.trigger because the $element might not be loaded yet when trigger is called
            $('#' + $el.attr('id')).trigger('progress:complete');
          }
        }
      };

      player.on('timeupdate', timeupdate);

      const cleanup = () => {
        player.off('timeupdate', timeupdate);
        document.removeEventListener("turbolinks:visit", cleanup);
      }
      document.addEventListener("turbolinks:visit", cleanup);
    } else if (provider === "wistia") {
      window._wq = window._wq || [];
      const config = {
        id: $el.attr("id"),
        onReady: (player) => {
          if (last_video_location > 0) {
            player.time(last_video_location);
          }

          // Do not use percentWatched here because it refers to the actual time
          // spent watching, not the position of the seek bar
          player.bind('timechange', (seconds) => {
            const pct = parseInt(seconds / Math.floor(player.duration()) * 100);
            if (progress != pct) {
              progress = pct;
              $.ajax({ url: progress_update_path, method: 'post', data: { progress, seconds } });
              if (progress >= 99) {
                // This is a workaround for calling $el.trigger because the $element might not be loaded yet when trigger is called
                $('#' + $el.attr('id')).trigger('progress:complete');
              }
            }
          });
        }
      };

      _wq.push(config);

      const cleanup = () => {
        _wq.push({ revoke: config });
        document.removeEventListener("turbolinks:visit", cleanup);
      }
      document.addEventListener("turbolinks:visit", cleanup);
    }
  });

  $('[data-track-video-progress-id]').each((i, el) => {
    const $el = $(el);
    const provider = $el.data('video-provider');

    const video_id = parseInt($el.data("track-video-progress-id"));
    const video_name = $el.data("track-video-progress-name");

    // these are the only percentiles tracked for case studies
    const percentiles = [0, 25, 50, 75, 99];

    // avoid flooding Ahoy with events on every partial percentage change
    let percentages = [...Array(100).keys()];

    const trackView = (percent) => {
      const current_pct = parseInt(percent * 100);

      // Track unwatched percentages one time only
      const [percentages_watched, percentages_not_watched] = percentages.reduce(
        (acc, pct) => ((current_pct > pct ? acc[0] : acc[1]).push(pct), acc),
        [[], []]
      );

      percentages_watched.forEach(pct => {
        if (percentiles.includes(pct)) {
          ahoy.track(`$${video_name}_video_view`, {
            video_id,
            progress: (pct == 99 ? 100 : pct), host: window.location.host,
          })
        }

        ahoy.track(`$${video_name}_granular_video_view`, {
          video_id,
          progress: (pct == 99 ? 100 : pct), host: window.location.host,
        })
      });

      percentages = percentages_not_watched;
    };

    if (provider === "vimeo") {
      const player = new Vimeo.Player(el);

      const timeupdate = ({ percent }) => trackView(percent);
      player.on('timeupdate', timeupdate);

      const cleanup = () => {
        player.off('timeupdate', timeupdate);
        document.removeEventListener("turbolinks:visit", cleanup);
      }
      document.addEventListener("turbolinks:visit", cleanup);
    } else if (provider === "wistia") {
      window._wq = window._wq || [];
      const config = {
        id: $el.attr("id"),
        onReady: (player) => {
          wistiaChapterSetup(player, video_id)
          // Do not use percentWatched here because it refers to the actual time
          // spent watching, not the position of the seek bar
          player.bind('timechange', (seconds) => {
            const percent = seconds / Math.floor(player.duration());
            trackView(percent);
          });
        }
      };
      _wq.push(config);

      const cleanup = () => {
        _wq.push({ revoke: config });
        document.removeEventListener("turbolinks:visit", cleanup);
      }
      document.addEventListener("turbolinks:visit", cleanup);
    }
  });

  $('[data-track-resource-click-id]').each((i, el) => {
    const $el = $(el);
    const resource_id = parseInt($el.data("track-resource-click-id"));
    const resource_title = $el.data("track-resource-click-title");
    $el.on('click', function () {
      ahoy.track(`$Resource_click`, { resource_id, resource_title })
    });
  });

  window.layoutHelpers.setCollapsed(true, false)

  $('.autoresize').on('input focus', function () {
    this.style.height = "";
    this.style.height = this.scrollHeight + 3 + "px";
  });
  $('.comment-container').on('focusin', function () {
    this.style.borderColor = "#113559"
  }).on('focusout', function () {
    this.style.borderColor = "#E8E9E9"
  });

  $(document).on("click", '[data-hide-edit]', function () {
    let id = $(this).attr('data-hide-edit')
    $(`#card-${id}`).show()
    $(`#edit-card-${id}`).hide()
  });
  $(document).on("click", '[data-open-edit]', function () {
    let id = $(this).attr('data-open-edit')
    $(`#card-${id}`).hide()
    $(`#edit-card-${id}`).show().find("textarea").focus()
  })

  $(document).on("click", '[data-delete-csv-row]', function () {
    $(this).closest('.row-container').remove();
  });

  $('#csv-selector').on('change', function () {
    $('#csv-loading').show();
    $('#csv-submit').trigger('click');
    this.value = this.defaultValue;
  });

  $('#clear-csv-data').on('click', function () {
    bootbox.confirm('Are you sure you want to clear your data?', function (ok) {
      if (!ok) return;
      $('#csv-data-container').empty();
      $('#error-results').hide()
    });
  });

  // Admin spotlight
  const adminMenuItems = $('#navbarAdminDropdown .dropdown-menu a.dropdown-item');

  $('#navbarAdminDropdown').on('shown.bs.dropdown', function () {
    $('#admin_forum_search').trigger('focus');
  })

  $('#navbarAdminDropdown').on('hidden.bs.dropdown', function () {
    $('#admin_forum_search').val('').trigger('change');
  })

  $('#admin_forum_search').on('input change', function(e) {
    const search = e.target.value?.toLowerCase().replace(/\s/g, '');
    const $dividers = $('#navbarAdminDropdown .dropdown-menu .dropdown-divider');

    if (!search) {
      $dividers.show();
    } else {
      $dividers.hide();
    }

    adminMenuItems.each((index, item) => {
      const $item = $(item);

      if (!e.target.value) {
        $item.show();
      } else {
        const label = $item.text().trim().toLowerCase().replace(/\s/g, '');
        $item.toggle(label.includes(search));
      }
    });
  });

  // Force show modals where needed
  $('[data-modal-show=true]').modal('show');

  dragscroll.reset()
  initPage();
});

document.addEventListener("turbolinks:before-cache", function () {
  $('[data-select2-el],[data-select2-remote-el]').each((i, el) => {
    $(el).select2('destroy')
  });
  $('#growls-default').remove();

  $('input.timepicker').timepicker('remove');

  if (window.drake) {
    window.drake.destroy();
    window.drake = null;
    delete window.drake;
  }

  if (window.tour) {
    window.tour.steps.forEach(step => step.destroy());
    window.tour = null;
    delete window.tour;
  }
})

/*
 * Handle select2 autofocus bug
 * ref: https://github.com/select2/select2/issues/5993
 * ref: https://github.com/jquery/jquery/issues/4382
 */
$(document).on('select2:open', () => {
  setTimeout(() => document.querySelector('.select2-container--open .select2-search__field').focus(), 10);
});

export { initializeDropzone } from "./dropzone_options";
export { attachTribute } from './tribute';
export { onTurbolinksLoad } from './turbolinks-helpers';
