import StickySidebar from 'sticky-sidebar';

const initCampSidebar = function () {
  if (document.Camp.sidebar && document.Camp.sidebar.destroy) {
    document.Camp.sidebar.destroy();
  }

  const containerPosition = $('.sidebar__container').position();
  const headerHeight = $('#header-title-container').outerHeight();

  document.Camp.sidebar = new StickySidebar('.sidebar__main', {
    containerSelector: '.sidebar__container',
    innerWrapperSelector: '.sidebar__inner',
    topSpacing: (containerPosition?.top || 0) - (headerHeight || 0),
    minWidth: 768,
  });
}

$(document).on("turbolinks:load", () => {
  const sidebarCount = document.querySelectorAll('.sidebar__container').length
  if (sidebarCount > 0) {
    initCampSidebar();

    window.addEventListener('resize', initCampSidebar, { passive: true });
    $(document).on('show.bs.collapse', '.sidebar__container .collapse', initCampSidebar);
    $(document).on('shown.bs.collapse', '.sidebar__container .collapse', initCampSidebar);
    $(document).on('hide.bs.collapse', '.sidebar__container .collapse', initCampSidebar);
    $(document).on('hidden.bs.collapse', '.sidebar__container .collapse', initCampSidebar);
  }
});

$(document).on("turbolinks:visit", () => {
  window.removeEventListener('resize', initCampSidebar);
  $(document).off('show.bs.collapse', '.sidebar__container .collapse', initCampSidebar);
  $(document).off('shown.bs.collapse', '.sidebar__container .collapse', initCampSidebar);
  $(document).off('hide.bs.collapse', '.sidebar__container .collapse', initCampSidebar);
  $(document).off('hidden.bs.collapse', '.sidebar__container .collapse', initCampSidebar);

  if (document.Camp.sidebar && document.Camp.sidebar.destroy) {
    document.Camp.sidebar.destroy();
  }
});
